import { navigate } from 'gatsby';
import React from 'react';
import { Helmet } from "react-helmet"
import Layout from '../components/layout';

function IndexPage() {
  let user;

  if (typeof window !== 'undefined') {
    user = window.localStorage.getItem('gatsbyUser');

    if (user !== null) {
      user = JSON.parse(user).username;
    }
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bruiloft Thom en Cynthia</title>
      </Helmet>
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-full overflow-hidden countdown-header">
          <p>Bedankt dat je deze dag met ons mee hebt gevierd!<br />Thom en Cynthia</p>

          <p class="smaller-p">Klik op de button om de foto's te beworderen.</p>

          <div class="grid grid-cols-3">
            <div>
              <a
                href="https://picti.net/vR5UB"
                target="_blank"
                className="link-button mt-3 border-b-4 bg-black px-4 py-2 rounded text-white"
                rel="noopener noreferrer nofollow">
                  Bekijk de foto's
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
